import axios from 'axios'

const initialState = {

}

export default (state = initialState, action) => {
	switch (action.type) {
		default:
			return state
	}
}