import React from 'react'
import { Route } from 'react-router-dom'

import Dashboard from '../dashboard'

const App = () => (
	<React.Fragment>
		<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" />

		<Route exact path="/" component={Dashboard} />
	</React.Fragment>
)

export default App
