import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Typed from 'react-typed';

class Dashboard extends React.Component{
	state = {
		loaded: false
	}

	componentWillMount(){
		setTimeout(() => {
			this.setState({
				loaded: true
			})
		}, 2000)
	}

	render(){
		return (
			<React.Fragment>
				<div className="logo-wrap">
					<nav className={(this.state.loaded ? ' show' : '')}>
						<a href="#our-work">Our Work</a>
						<a href="#about-us">About Us</a>
						<a href="mailto:sam@wearekode.com">Get In Touch</a>
					</nav>

					<svg className={'arrow' + (this.state.loaded ? ' show' : '')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.5 35" overflow="visible">
						<path d="M1.3,1.5c12.4,10.3,24.8,20.6,37.2,30.9C51.1,22.1,63.7,11.8,76.3,1.5" fill="none" stroke="#212529" stroke-width="4" stroke-miterlimit="10"/>
					</svg>

					<div className={"logo"}>
						<span>WeAre</span>
						<Typed
							style={{
								fontWeight: 'bold'
							}}
							strings={[
								'Kode',
								'Reliable',
								'Trusted',
								'Innovative',
								'Passionate',
								'Kode']}
							typeSpeed={40}
							backSpeed={50}
							cursorChar={'_'}
							backDelay={2000}
							fadeOutDelay={750}
						/>
					</div>
				</div>

				<div className="work-wrap">
					<div id={'our-work'} className="container">
						<h2>Our Work</h2>
					</div>

					<div className="container">
						<h3>Cyber Security</h3>

						<div className="work">
							<a target={'_blank'} href={'https://cynet.com'} className="item">
								<img src="/images/screenshot-cynet.png" alt=""/>

								<p><strong>Cynet</strong> <br/>WordPress, HubSpot, React</p>
							</a>

							<a target={'_blank'} href={"https://doubleoctopus.com"} className="item">
								<img src="/images/screenshot-sdo.png" alt=""/>

								<p><strong>Secret Double Octopus</strong> <br/>WordPress, HubSpot</p>
							</a>

							<a target={'_blank'} href={'https://alcide.io'} className="item">
								<img src="/images/screenshot-alcide.png" alt=""/>

								<p><strong>Alcide</strong><br /> WordPress, HubSpot</p>
							</a>

							<a target={'_blank'} href={'https://authomize.com/'} className="item">
								<img src="/images/screenshot-authomize.png" alt=""/>

								<p><strong>Authomize</strong> <br />WordPress, HubSpot</p>
							</a>

							<a href={'https://catonetworks.com'} target={'_blank'} className="item">
								<img src="/images/screenshot-cato.png" alt=""/>

								<p><strong>Cato Networks</strong> <br/>Zendesk</p>
							</a>

							<div target={'_blank'} href={'https://calcomsoftware.com'} className="item">
								<img src="/images/screenshot-calcom.png" alt=""/>

								<p><strong>Calcom Software</strong><br/> WordPress</p>
							</div>
						</div>
					</div>

					<div className="container">
						<h3>Tech</h3>

						<div className="work">
							<a href={'https://rookout.com'} className="item">
								<img src="/images/screenshot-rookout.png" alt=""/>

								<p><strong>Rookout</strong><br/> WordPress, Static Hosting</p>
							</a>

							<a href={'https://flolive.net'} className="item">
								<img src="/images/screenshot-flolive.png" alt=""/>

								<p><strong>FloLIVE</strong><br/> WordPress</p>
							</a>
						</div>
					</div>

					<div className="container">
						<h3>Travel</h3>

						<div className="work">
							<a href={'https://notintheguidebooks.com'} className="item">
								<img src="/images/screenshot-nitgb.png" alt=""/>

								<p><strong>Not In The Guidebooks</strong><br/> WordPress, 3rd Party API Integration</p>
							</a>

							<a href={'https://exodus.co.uk'} className="item">
								<img src="/images/screenshot-exodus.png" alt=""/>

								<p><strong>Exodus</strong><br/> Custom PDF Generation</p>
							</a>
						</div>
					</div>

					<div className="container">
						<h3>Finance</h3>

						<div className="work">
							<a target={'_blank'} href={'https://londoncareersfestival.org.uk'} className="item">
								<img src="/images/screenshot-lcf.png" alt=""/>

								<p><strong>City of London</strong><br/>WordPress, Custom Animation</p>
							</a>

							<a target={'_blank'} href={'https://insol.org'} className="item">
								<img src="/images/screenshot-insol.png" alt=""/>

								<p><strong>Insol International</strong><br/>Custom CMS, React, Stripe</p>
							</a>
						</div>
					</div>

					<div className="container">
						<h3>Branding & Design</h3>

						<div className="work">
							<a target={'_blank'} href={'https://landofplenty.studio'} className="item">
								<img src="/images/screenshot-lop.png" alt=""/>

								<p><strong>Land of Plenty</strong><br/>WordPress, Custom Animation</p>
							</a>
						</div>
					</div>

					<div className="container text-center">
						<a href="mailto:sam@wearekode.com;tony@wearekode.com" className="btn btn-primary">Get in Touch</a>
					</div>
				</div>

				{/*<div className="quotes">*/}
				{/*	<div className="container">*/}
				{/*		<blockquote>Working with Kode has made </blockquote>*/}
				{/*	</div>*/}
				{/*</div>*/}
				
				<div id={'about-us'} className="about">
					<div className="container">
						<h2>Who We Are</h2>

						<div className="columns">
							<p><strong>Kode</strong> is a full-stack development house founded in 2020 by Tony May and Sam Benson. </p><p>After working alongside each other for over a decade, Sam and Tony decided to join forces and put their complimentary skill-sets to good use. Each have brought their own experience in a variety of markets; Tony works mostly with UK design and marketing agencies, whereas Sam focuses on the Israeli cyber security sector.</p>
							<p>Here at <strong>Kode</strong> we pride ourselves on the relationships we form with our clients, our attention to detail and our proven track record of success.</p>
							<p>If you would like to know more about <strong>Kode</strong> and find out how we can help your business please get in touch, we'd love to hear from you.</p>
						</div>

						<div className="container text-center">
							<a href="mailto:sam@wearekode.com;tony@wearekode.com" className="btn btn-primary">Get in Touch</a>
						</div>
					</div>
				</div>

				<div className="footer">
					<div className="container">
						<div>
							<svg width={100} height={27} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1619 433">
								<g fill="none" fillRule="evenodd">
									<path d="M331.138 424h-96.164l-151.2-198.374V424H.312V.64h83.462v186.883L228.926.64h96.164L168.446 206.272 331.138 424zm127.746 8.467c-88.905 0-159.667-69.552-159.667-159.667s70.762-159.667 159.667-159.667c88.906 0 160.272 69.552 160.272 159.667S547.79 432.467 458.884 432.467zm0-76.205c46.57 0 82.253-34.473 82.253-83.462 0-48.989-35.683-83.462-82.253-83.462-45.965 0-81.648 34.473-81.648 83.462 0 48.989 35.683 83.462 81.648 83.462zM876.934.64h78.02V424h-78.02v-35.683c-21.772 27.82-53.826 44.15-97.977 44.15-81.043 0-147.571-69.552-147.571-159.667s66.528-159.667 147.571-159.667c44.15 0 76.205 16.33 97.978 44.15V.64zm-83.462 357.437c47.78 0 83.463-34.474 83.463-85.277 0-50.803-35.683-85.277-83.463-85.277-48.384 0-84.067 34.474-84.067 85.277 0 50.803 35.683 85.277 84.067 85.277zm272.294-53.223c10.887 39.917 44.15 56.852 85.277 56.852 30.24 0 54.432-12.701 67.738-30.24l62.899 36.288c-28.426 41.126-73.786 64.713-131.847 64.713-101.606 0-165.715-69.552-165.715-159.667s64.714-159.667 159.667-159.667c89.51 0 153.015 70.761 153.015 159.667 0 11.491-1.21 21.773-3.024 32.054h-228.01zm-1.21-60.48h154.225c-9.677-43.545-42.336-61.084-74.996-61.084-41.73 0-70.156 22.377-79.228 61.084z" fill="#fff"/>
									<path d="M1322 406.5h297" stroke="#fff" strokeWidth="35"/>
								</g>
							</svg>
						</div>

						<p>All rights reserved. Copyright Kode Developments Ltd 2021</p>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ( state ) => ({

})

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
